import { RENTAL_APPLICATION_NOT_FOUND } from '~/assets/strings';
import { StepError } from '~/state/mainAppState';
import { RentalApplicationErrorCode } from '~/types/RentalApplication';
import { RentalApplicationSteps } from '~/types/RentalApplicationSteps';
import { MagicUseCase } from '~/use-cases/magicUseCase';

export class ValidateUnionQuestionnaire extends MagicUseCase {
  protected async runLogic(payload: { questionKeyName: string; selectedChoiceKeyName: string }[]) {
    const application = this.getState().user.rentalApplication;

    if (!application) {
      throw new Error(RENTAL_APPLICATION_NOT_FOUND);
    }

    this.getState().user.rentalApplication.errors = { ...this.getState().user.rentalApplication.errors };
    this.getState().user.rentalApplication.errors[RentalApplicationSteps.TRANS_UNION_QUESTIONS] = {};

    this.getState().user.rentalApplication.transUnionScreening?.questions?.forEach(({ questionKeyName }) => {
      const hasAnswer = payload.find((answer) => answer.questionKeyName === questionKeyName && answer.selectedChoiceKeyName) !== undefined;
      (this.getState().user.rentalApplication.errors[RentalApplicationSteps.TRANS_UNION_QUESTIONS] as StepError)[questionKeyName] =
        hasAnswer ? undefined : RentalApplicationErrorCode.AnswerRequired;
    });
  }
}
